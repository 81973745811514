<template>
  <div class="bg-f">
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <div class="accout-content clearfix"  style="display:flex">
          <!-- 左边 -->
          <user-left mark="questionnaire" :userId="userId" />
          <!-- 右边 -->
           <a-card :bordered="false" class="card-right" style="width:100%">
            <a-table ref="table" :row-key="record=>{return record.questionnaireId}" :columns="columns" :data-source="list" :pagination="pagination" @change="onChange1">
                <span slot="action" slot-scope="text, record">
                  <router-link :to="{name: 'QuestionDetail', params: {id: record.questionnaireId, userId: userId } }">{{ $t('user.detail') }}</router-link>
                </span>
            </a-table>
           </a-card>
        </div>
      </div>
    </div>
     <!-- 公共尾部 -->
    <Footer></Footer>
  </div>
</template>

<style>
  .ant-card-body{
    text-align: left;
  }
  .ant-table-body{
    margin-top: 10px;
  }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'UsersQuestionnaire',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
      list: [],
      page: 1,
      userId: this.$route.params.userId,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showTotal: (total, range) => {
          return (<div>共 <span>{total}</span> 条</div>)
        },
        onShowSizeChange: (current, pageSize) => {
          this.pageSize = pageSize
        }
      },
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t('user.number'),
          dataIndex: 'questionnaireId',
        },
        {
          title: this.$t('user.questionnaire.title'),
          dataIndex: 'questionnaireTitle',
        },
        {
          title: this.$t('user.startTime'),
          dataIndex: 'startedAt',
        },
        {
          title: this.$t('user.endTime'),
          dataIndex: 'endAt',
        },
        {
          title: this.$t('user.questionnaire.status'),
          dataIndex: 'statusText',
        },
        {
          title: this.$t('user.questionnaire.fill'),
          dataIndex: 'fillText',
        },
        {
          title: this.$t('user.operation'),
          scopedSlots: { customRender: 'action' }
        },
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 改变每页数量时更新显示
    onChange1 (pagination) {
      this.pagination.defaultCurrent = pagination.current
      this.pagination.defaultPageSize = pagination.pageSize
      this.getList()
    },
     // 获取问卷列表
    getList () {
       const params = {
         page: this.pagination.defaultCurrent,
         pagesize: this.pagination.defaultPageSize
       }
       this.$common.fetchList('/user/questionnaire/lists', params).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result.lists
          console.log(this.list)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
  }
}
</script>